@font-face{font-family:Quicksand;font-style:normal;font-weight:400;font-display:swap;src:url(fonts/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58m-wi40.woff2) format('woff2');unicode-range:U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB}@font-face{font-family:Quicksand;font-style:normal;font-weight:400;font-display:swap;src:url(fonts/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58i-wi40.woff2) format('woff2');unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF}@font-face{font-family:Quicksand;font-style:normal;font-weight:400;font-display:swap;src:url(fonts/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58a-wg.woff2) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}
// Here you can add other styles

body,html{font-family:Quicksand !important;}
th{text-transform: capitalize;}
footer a, .breadcrumb a {
    color: #73818f !important;
    text-decoration: none;
}
.app-header {
    position: relative;
    flex-direction: row;
    height: 55px;
    padding: 0;
    margin: 0;
    background-color: #930f0f;
    border-bottom: 1px solid #c8ced3;
}
.navbar-nav .nav-link {
    color: #f7f9fc;
}
.navbar-nav .open > .nav-link, .navbar-nav .open > .nav-link:hover, .navbar-nav .open > .nav-link:focus, .navbar-nav .active > .nav-link, .navbar-nav .active > .nav-link:hover, .navbar-nav .active > .nav-link:focus, .navbar-nav .nav-link.open, .navbar-nav .nav-link.open:hover, .navbar-nav .nav-link.open:focus, .navbar-nav .nav-link.active, .navbar-nav .nav-link.active:hover, .navbar-nav .nav-link.active:focus {
    color: #f7f9fc;
}

.card{border: none;border-radius: 0 !important}
.card-header {
    height: 45px;
    background: transparent;
}

.card-title {
    font-weight: bold;
    opacity: .9;
    color: #932c12;
    text-transform: capitalize;
    line-height: 24px;
}
.bg-danger {
    background-color: #932c12 !important;
}
.text-danger {
    color: #932c12 !important;
}
.card-header-actions{position: absolute;right: 0;top: 0;padding: 9px;text-align:right;width:65%;}
.card-header-actions .rs-picker-toggle-wrapper{width:75%;}
.card-header-actions .btn-md{margin-top:-8px;height:34px;margin-right:2px;}

.rdt_TableHeader{display: none !important;}
.rdt_TableCol{font-weight: bold !important;}
.rdt_TableHeadRow, .rdt_TableRow{min-height: 32px !important;}
.rdt_TableRow:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05) !important;
}
.doBktq{border:none !important;}
.rdt_TableCell, .rdt_TableCol{
    border: 1px solid #d4dbe1 !important;
    padding-left:8px !important;
    padding-right:8px !important;
    min-width: 50px !important;
}

.card label{color:#73818f;}

.form-control {
    display: block;
    width: 100%;
    height: calc(2rem + 2px);
    padding: 0.25rem 0.35rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-md, btn-sm, .btn{text-transform: capitalize !important;}
.btn-md, .btn-group-md>.btn {
    padding: 0.3rem 0.8rem;
    font-size: 0.76563rem;
    line-height: 20px;
    border-radius: 0;
    font-weight:bold;
}
.btn-outline-secondary {
    color: #727476;
    background-color: transparent;
    background-image: none;
    border-color: #c8ced3;
}

.rs-picker-daterange-menu{z-index: 9997 !important}


p{color:red;}
.logo{width: 90px}

.circle-avatar {
  width: 150px;
  height: 150px;
  margin: 5px auto;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  border: 1px solid #ccc;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
  }
}



.form-control-1 {
  display: block;
  min-height: 28px;
  max-height: 28px;
  height: 28px;
  margin: 0;
}
.label-span-style {
  label {
    span {
      width: 33% !important;
      float: left;
      font-weight: 400;
      text-align: right !important;
      margin-right: 12px;
    }
    font-weight: bold;
    max-height: none !important;
    height: auto !important;
    line-height: inherit;
    clear: both;
  }
}
fieldset {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 24px;
}
legend {
  width: auto !important;
  font-size: inherit !important;
  color: #000;
  padding: 5px 10px;
  margin-bottom: 0px;
  margin-left: 16px;
  color: #777;
  font-weight: bold;
}
.mg-b-12 {
  margin-bottom: 12px;
}
.sidebar {
  .nav {
    width: 210px;
  }
  .sidebar-nav {
    width: 210px;
  }
}
.sidebar-fixed {
  .sidebar {
    width: 210px;
  }
}
.modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.custom_modal {
  position: fixed;
  width: 80%;
  left: 10%;
  top: 24px;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 90%;
  overflow: hidden;
  outline: 0;
  max-width: 60%;
  margin: 1.75rem auto;
  padding: 9px;
  border: 1px solid #000;
  background: rgba(0,0,0,.9);
}
.ReactCrop__image {
  max-height: 450px;
  width: auto;
  max-width: 100%;
}
.show {
  display: block;
}
.hide {
  display: none;
}


[type="file"] {
  height: 150px;
  width: 200px;
  opacity: 0;
  border: 1px solid;
  margin-bottom: 12px;
  + {
    label {
      background: #ccc;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-family: 'Poppins', sans-serif;
      font-size: inherit;
      font-weight: 600;
      margin-bottom: 1rem;
      outline: none;
      padding: 1rem 50px;
      position: relative;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      vertical-align: middle;
      &:hover {
        background-color: #d3460d;
      }
    }
    label.btn-3 {
      background-color: #ccc;
      border-radius: 0;
      overflow: hidden;
      position: absolute;
      left: 15px;
      width: 200px;
      height: 150px !important;
      span {
        display: inline-block;
        height: 100%;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        width: 100%;
        margin-left: 24px;
        font-size: 45px;
        margin-top: 25px;
        color: #930f0f;
      }
      &::before {
        color: #930f0f;
        content: "\f093";
        font-family: "FontAwesome";
        font-size: 45px;
        height: 100%;
        left: 40%;
        right: 40%;
        top: 30%;
        bottom: 30%;
        line-height: 2.6;
        position: absolute;
        top: -180%;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        width: 100%;
      }
      &:hover {
        background-color: rgba(0,0,0,.6);
        span {
          -webkit-transform: translateY(300%);
          transform: translateY(300%);
        }
        &::before {
          top: 15px;
        }
      }
    }
  }
}

.crop_result_img {
  max-width: 200px !important;
  left: 15px;
  position: relative;
  opacity: .5;
}
.box {
  border: 2px solid #ccc;
  padding: 8px;
}
.text-red {
  color: #932c12 !important;
}

.inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;

    label {
      padding: 12px 40px;
      width: 100%;
      display: block;
      text-align: left;
      color: #777;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 28px;
        height: 28px;
        content: '';
        border: 2px solid #D1D7DC;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
         background-image: url("../assets/img/checked.svg");
        background-repeat: no-repeat;
        background-position: 0px 0px;
        z-index: 2;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #333;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #ba242d;
        border-color: #ba242d;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
}



$input-bg-static: #ddd;
$input-bg-pseudo-active: #43A047;
$input-bg-pseudo-static: #E53935;
$input-bg-light-active: #FFFF00;
$input-width: 48px; // adjust only this number for dimensions
$input-radius: calc( #{$input-width} / 2 );
$input-height: calc( #{$input-width} / 2 );
$input-light-dims: calc( #{$input-height} / 2.5 );
.toggleItem {
    padding:20px 0;
    .checkboxLabel{position: absolute;
    display: inline-block;
    margin-left: 16px;
    top: 22px;
    line-height: 40px;}
    input[type="checkbox"] {
      cursor:pointer;
      position: relative;
      appearance: none;
      width: $input-width;
      height: $input-height;
      background-color: $input-bg-static;
      border-radius: $input-radius;
      outline: none; // bad accessibility
      transition: background 450ms ease;
      box-shadow: 0 0 0 3px darken($input-bg-static, 7.5%);

      &:before, &:after {
        position: absolute;
        display: block;
        content: "";
        border-radius: 100%;
        transition:
          background 450ms ease,
          transform 450ms ease;
      }

      &:before {
        width: calc( #{$input-width} / 2 );
        height: $input-height;
        background-color: $input-bg-pseudo-static;
      }

      // active state
      &:checked {

        &:before {
          background-color: $input-bg-pseudo-active;
          transform: translateX(100%);
        }
      }
    }
}
.react-notification-root{position:relative !important;}

.swal2-popup{padding: 6px 24px !important;border-radius: 0 !important;width:29em !important;}
.swal2-title{font-size:24px !important;}
.swal2-icon {width: 4em !important;height: 4em !important;margin: 1em 1em !important;}
.swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel{border-radius:0 !important;font-size:14px !important;}

.imageFrame img{max-width:90%; border:1px solid #eee;padding:9px;text-align:center}
.Select.is-disabled > .Select-control {
   background-color: #e3e1e1;
}

.rdt_TableRow .btn-pill{font-size:11px;padding:0px 6px;}

.qrPrintFrame {
        width: 320px;
        height: 510px;
        padding: 16px 10px;
        margin: 0 auto;
        background: #ab3522 url('../assets/img/bgqr.jpg') no-repeat center center !important;
        font-family: sans-serif;
        text-align: center;
        clear: both;
        display: block;
        color: #fff !important;
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: bold;
    }

    .qrPrintFrame .box {
        width: 290px !important;
        padding:10px 5px;
        margin: 0 auto;
        background: transparent;
        border: 1px solid #aaa;
    }
    .qrPrintFrame2{width: 310px;height: 310px;padding:10px 5px;margin:0 auto;background:#ffffff;font-family:sans-serif !important;text-align:center;clear:both;display:block;color:#000!important;font-size: 12px !important;line-height: 16px !important;}
    .qrPrintFrame2 .box{width: 300px !important;height:300px;padding:8px; margin:0 auto !important;background:transparent !important;border: 1px solid #9EA1A5;}

    .mg-btm-8 {
        margin-bottom: 8px !important
    }

    .mg-top-8 {
        margin-top: 8px !important
    }


    .font-29 {
        font-size: 20px !important;
        line-height: 20px !important;
        margin: 6px !important;
    }
    .regName{
       overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 2; /* number of lines to show */
       -webkit-box-orient: vertical;
       height: 38px !important;
    }

    .qrPadding{
      padding:8px; background:#eee;max-width: 100%;max-height: 80%;
    }
    .qrPadding-white{
      padding:8px; background:#fff;
    }


.popup-box{position:absolute; top:125px; right:30px;z-index:99999;border:1px solid #eee;}
.iconStyle{font-size:40px;position:absolute;z-index:99;top:35%;left:45%;color:#a51010;opacity:.01}
.qrcode{cursor:pointer;position:relative;}.qrcode:hover .iconStyle{opacity:1}


.nav-tabs .nav-link{border-radius:0;border:2px solid #ddd; background:#eee}
.nav-tabs .nav-link.active {
    color: #2f353a;
    background: #fff;
    border-color: #c8ced3;
    border-bottom-color: #fff;
    background: #930f0f;
    color: #fff;
    border-radius: 0;
    border: 2px solid #bbadad;
        border-bottom-color: rgb(187, 173, 173);
        border-bottom-style: solid;
        border-bottom-width: 2px;
    border-bottom: 2px solid #fff;
}
.nav-tabs .nav-link.active:hover{color:#fff}
.income{color:green}
.outcome{color:red}
.note span{min-width:150px; float:left;}
.note span:after{content:": "; float:right;}
.pull-right label{margin-top:16px; }
@media (max-width: 768px) {
  .popup-box {
      position: absolute;
      top: 125px;
      right: 4px;
      z-index: 99999;
      border: 1px solid #eee;
      max-width: 98%;
  }

  .card-header{height:135px;}
  .card-header-actions{width:100%;top:45px;border-top: 1px solid #ccc;
  left: 0;}
  .card-header-actions .col-xs-12{margin-bottom:14px !important;}
  .rs-picker-menu{left:0 !important;width:90%;margin-left:5%;z-index: 9999999999 !important;}
  .rs-picker-daterange-calendar-group{height:auto !important; max-width:100% !important;min-width:360px !important;}
  .rs-picker-daterange-menu .rs-calendar{width: 80% !important;}

  .rs-calendar-header ::before, .rs-calendar-header ::after {
      box-sizing: inherit !important;
  }
}



.checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #1E2235;
    --tick: #fff;
    position: relative;
    cursor: pointer;
    width:100%;
    input,
    svg {
        width: 21px;
        height: 21px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        float: left;
        margin: 0;
        margin-right: 8px;
        outline: none;
        background: var(--background);
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        float:left;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: .4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}


.restrictFrame, .boxFrame{border:1px solid #ccc; padding:9px; margin:4px 0;}
.restrictFrame .fa{font-size:16px;margin-right:12px;cursor:pointer}
.addedIP .fa-trash{ opacity:0}
.addedIP:hover .fa-trash{ opacity:1}
.cursorPointer {cursor: pointer}

.fa-lg{font-size:1.33em;}
.deviceInfo div span{width:25% !important;float:left;font-weight: normal;}
.deviceInfo div span:after{content: ":"; float:right;margin-right: 12px;}
.deviceInfo div{font-weight: bold;}
// .deviceInfo div{padding-left:40% !important;}


.float-right{float: right;}
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}
.pagination > .active > a{
  background-color: #222222 ;
  border-color: #222222 ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #222222 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #222222 ;
  border-color: #222222;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #222222
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.trashBtn{position: absolute;float: right; right: 12px; top:5px;}
.trashBtn .fa{cursor: pointer;border:2px solid #ccc;padding:8px;color:#777;}
.trashBtn .fa:hover{color: red; border:2px solid #ccc;padding:8px;}

.ReactModal__Overlay--after-open{z-index: 1059 !important}

.react-notification-root{position: fixed !important;}
.notification-container-top-right{right: 258px !important; top:0 !important;}
.swal2-input {
    height: 40px !important;
    padding: 8px !important;
    border-radius: 0 !important;
    font-size: 16px !important;
}

.rightLabel label{float: right !important}
.boxShadow{box-shadow: 0px 0px 4px #ccc;}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  cursor: pointer;
  z-index: 9998 !important
}
.reactLoading{display:inline-block !important; position: fixed;z-index: 9999;}
.swal2-container{z-index: 10000 !important;}

.label-span-style label span.required {position: absolute;color: red;right: 0;font-weight: bold;}
.left-0{left:12px;}
.fa-copy{cursor: pointer;}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #930f0f; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}